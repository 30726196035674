import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="プライバシーポリシー｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-privacyPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-lg sm:text-xl py-10">プライバシー<br />ポリシー</h1>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section className="mb-20">
              <h2 className="text-2xl border-b border-key-sand my-5 py-3 ">
              個人情報保護方針
              </h2>
              <ul>
                <li className=" list-decimal ml-5 mb-5">
                  <h3 className=" font-normal ">理念</h3>
                  <p>
                  株式会社スタートアッププラス（以下、「当社」とします）は、情報システムの企画・開発・運用を提供しています。情報システムは大量の情報を素早く効率よく処理し、利用者に利便性をもたらしますが、個人情報の不適切な管理や、取り扱いにより漏洩等の事故が発生した場合、大きな被害を引き起こす可能性があります。<br />
                  そのため、当社では法令を遵守し、個人情報を適切に特定し、取り扱うため、個人情報の保護に関する以下の方針を定めます。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h3 className=" font-normal ">個人情報の取得、利用</h3>
                  <p>
                  当社は当社が必要とする範囲内でのみ個人情報を取得します。また、個人情報は、個人情報を取得する際に示した目的の範囲内でのみ利用します。<br />
                  そのため、個人情報を取得する際には、その目的、利用方法、管理責任者などを記録、確認します。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h3 className=" font-normal ">法令・規範の遵守と見直しについて</h3>
                  <p>
                  当社は個人情報に関して適用される法令・規範を遵守し、個人情報保護の取り組みの継続的な改善・向上に努めます。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h3 className=" font-normal ">個人情報の管理について</h3>
                  <p>
                  当社は個人情報を適切に管理し、不当なアクセスや個人情報の紛失、破壊、改ざん、漏洩等に対する予防措置および安全対策を講じます。<br />
                  また、個人情報の保護と適切な取り扱いに関する社内教育を継続して実施します。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h3 className=" font-normal ">個人情報に関するお問い合わせについて</h3>
                  <p>
                  当社では当社が取り扱う個人情報への相談や苦情、保有している個人情報の利用目的、開示、訂正等、また利用の停止等のお申し込みを受け付けております。以下の相談、お問い合わせ、苦情受付窓口までご連絡ください。<br />
                  当社は、ご本人がご自身の個人情報の照会・修正・削除などを希望される場合には、所定の手続きに基づき速やかに対応いたします。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h3 className=" font-normal ">個人情報保護マネジメントシステムの継続的改善について</h3>
                  <p>
                  当社は、個人情報に関して適用される法令、規範を遵守し、個人情報を適正に取り扱うための社内規程の整備、教育、監査の実施等を定期的に行い、個人情報保護マネジメントシステムの改善を進め社内体制の継続的な改善・強化に努めます。
                  </p>
                </li>
              </ul>
            </section>

            <section className="mb-20">
              <h2 className="text-2xl border-b border-key-sand my-5 py-3 ">
              当社における個人情報の取扱いについて
              </h2>
              <p className="mb-5">
              株式会社スタートアッププラス(以下、「当社」とします)は、個人情報を取得するに辺り、個人情報の安全性の確保、保護・管理を行う上で適切な処置を講じます。
              </p>
              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              個人情報の利用目的について
              </h3>
              <p className="ml-5 mb-5">
              当社は、お客さまから取得した個人情報を次の目的のために利用します。
              </p>
              <ul className="ml-5 ">
                <li className=" list-decimal ml-5 mb-5">
                  <h4>
                    お客様・お取引先、その他関係者の情報の場合
                  </h4>
                  <p>
                  お客様へのサービスのご案内、情報提供、また当社とのお取引上必要になるご連絡のために利用いたします。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h4>
                    当社サービスのご利用者の情報の場合
                  </h4>
                  <p>
                  1）モニタリングプラスのご利用者の個人情報<br />
                  　　モニタリングプラスの利用規約に基づいて適切にサービスを提供するために利用いたします。<br />
                  2）NuRse Callのご利用者の個人情報<br />
                  　　NuRse Callの利用規約に基づいて適切にサービスを提供するために利用いたします。<br />
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h4>
                    採用活動時の情報の場合
                  </h4>
                  <p>
                  当社の求人募集にご応募、お問い合わせ頂いた方の個人情報は、採用選考の実施、その他採用活動に必要な連絡のために利用いたします。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h4>
                    受託案件に関わる個人情報の場合
                  </h4>
                  <p>
                  委託された業務案件に関わる個人情報は、その業務を行う目的のために利用いたします。
                  </p>
                </li>
                <li className=" list-decimal ml-5 mb-5">
                  <h4>
                    お問合せの場合
                  </h4>
                  <p>
                  お問い合わせに対してのご連絡のために利用いたします。
                  </p>
                </li>
              </ul>

              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              個人情報の第三者への提供について
              </h3>
              <p className="ml-5 mb-5">
              当社では、あらかじめご本人の同意を得ることなく、個人情報を第三者に提供することはありません。<br />
              ただし、次の場合には、あらかじめご本人の同意を得ることなく、個人情報を第三者に提供することがありますので、ご了承ください。
              </p>
              <p className="ml-10">
              a)　法令に基づく場合<br />
              b)　人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br />
              c)　公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br />
              d)　国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき
              </p>

              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              個人情報の委託について
              </h3>
              <p className="ml-5 mb-5">
              当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いを委託することがあります。<br />
              委託にあたっては、委託先における個人情報の安全管理が図られるよう、委託先に対する必要かつ適切な監督を行います。
              </p>

              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              個人情報をご提供頂けなかった場合の影響について
              </h3>
              <p className="ml-5 mb-5">
              情報をご提供頂けなかった場合には、当社サービスの提供等ができない可能性がございます。
              </p>

              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              Cookieについて
              </h3>
              <p className="ml-5 mb-5">
              当社Webサイトには、Cookie（クッキー）と呼ばれる技術及びこれに類する技術を使用しているページがあります。Cookieとは、Webサイトを快適にご利用できるように、Webサーバが当該Webサイトの利用者のブラウザの間で送受信される情報です。なお、Cookie自体に個人を特定する情報は一切含まれておりません。<br />
              当社では、お客様の利便性の向上、統計データの取得を目的として、Cookieを利用することがあります。<br />
              本事項にご同意いただけない場合は、ウェブブラウザの設定を変更することにより、Cookieの受け取りを拒否し、または、Cookieを受け取った場合に警告を表示させることができます。詳しくは、ご使用のブラウザの説明をご覧ください。ただし、コンテンツによっては当社Webサイトが正しく機能せず、サービスがご利用いただけなくなる場合もありますので、ご注意ください。
              </p>

              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              安全管理措置
              </h3>
              <p className="ml-5 mb-5">
              当社は、個人情報への不正アクセス又は個人情報の漏えい、滅失又は毀損の防止その他の個人情報の安全管理のために、技術的及び組織的に厳重なセキュリティ対策を講じます。当社は個人情報の保護を継続的・向上的に行っていくため、当社が定めた社内規程等を法令及び社会規範の変化に合わせて見直し、改善をしていきます。<br />
              また、個人情報は、利用目的の達成に必要な期間保持します。
              </p>

              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              保有個人データについて
              </h3>
              <p className="ml-5 mb-5">
              すべての「保有個人データ」の利用目的や「開示等の請求等」の手続き等「保有個人データに関する事項の周知」事項につきましては、以下の「個人情報に関する相談、お問い合わせ、苦情受付窓口」までご連絡ください。遅滞なく回答いたします。
              </p>

              <h3 className="text-lg md:text-xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              個人情報に関する相談、お問い合わせ、苦情受付窓口
              </h3>
              <p className="ml-5 mb-5">
              株式会社スタートアッププラス 　個人情報相談窓口<br />
              住所：　東京都港区赤坂6-4-19　赤坂T.S.C.ビル5階<br />
              メールアドレス：　privacy-support-sup＠startup-plus.com<br />
              (「＠」を半角に変更しメール送信をお願いいたします)
              </p>

            </section>

            <section className="mb-20">
              <p className="text-right">
                2013年2月28日制定<br />
                2022年4月1日最終改定<br />
                株式会社スタートアッププラス<br />
                代表取締役　桒山　雄介<br />
              </p>
            </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
